// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artroscopia-js": () => import("./../../../src/pages/artroscopia.js" /* webpackChunkName: "component---src-pages-artroscopia-js" */),
  "component---src-pages-cirugias-js": () => import("./../../../src/pages/cirugias.js" /* webpackChunkName: "component---src-pages-cirugias-js" */),
  "component---src-pages-enfermedades-tratadas-js": () => import("./../../../src/pages/enfermedades-tratadas.js" /* webpackChunkName: "component---src-pages-enfermedades-tratadas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesiones-deportivas-js": () => import("./../../../src/pages/lesiones-deportivas.js" /* webpackChunkName: "component---src-pages-lesiones-deportivas-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tratamientos-biologicos-js": () => import("./../../../src/pages/tratamientos-biologicos.js" /* webpackChunkName: "component---src-pages-tratamientos-biologicos-js" */),
  "component---src-pages-traumatologia-js": () => import("./../../../src/pages/traumatologia.js" /* webpackChunkName: "component---src-pages-traumatologia-js" */)
}

